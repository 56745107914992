<template>
  <div>
    <manager
      v-if="matchType != 'league'"
      v-loading="loading"
      :match_data="match_data"
      :view_type="view_type"
    ></manager>
    <managerLeague
      v-else
      v-loading="loading"
      :match_data="match_data"
      :view_type="view_type"
    ></managerLeague>
  </div>
</template>

<script>
import manager from "./manager";
import managerLeague from "./managerLeague";

import { mapActions } from "vuex";
export default {
  name: "edit",
  components: {
    manager,
    managerLeague,
  },
  data() {
    return {
      type: "s1",
      match_data: {},
      loading: true,
      view_type: "",
      matchType: "",
    };
  },
  async activated() {
    this.matchType = localStorage.getItem("matchType");
    this.type = this.$route.query.type_id;
    this.view_type == this.$route.query.view_type;
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.loading = true;
    let _id = this.$route.query._id;
    if (_id) {
      let { data, errorCode } = await this.$http.match.detail(_id);
      if (errorCode != "0000") {
        this.removeTabByPath(`/match/info/info_s1/add`);
        this.removeTabByPath(`/match/info/info_s1/edit`);
        return;
      }

      this.match_data = {
        ...data.match,
        has_stage: data.has_stage,
      };
    } else {
      this.removeTabByPath(`/match/info/info_s1/add`);
      this.removeTabByPath(`/match/info/info_s1/edit`);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style scoped></style>
